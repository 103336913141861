import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";

export default function SearchInput(props: {
    onSearch?(value: string): void
} & Omit<React.ComponentProps<typeof TextField>, "onBlur" | "onChange">) {

    const { onSearch, ...textFieldAttributes } = props;
    const [searchTerm, set_searchTerm] = React.useState("");

    let delayDebounceFn: NodeJS.Timeout | undefined;

    React.useEffect(() => {
        delayDebounceFn = setTimeout(() => {
            props.onSearch && props.onSearch(searchTerm);
            // Send Axios request here
        }, 700);

        return () => {
            clearTimeout(delayDebounceFn);
            delayDebounceFn = undefined;
        }
    }, [searchTerm]);

    return (<TextField
        variant="outlined"
        fullWidth
        placeholder="Buscar..."
        value={searchTerm}
        type="search"
        {...textFieldAttributes}
        onChange={event => set_searchTerm(event.target.value)}
        onBlur={event => {
            if (delayDebounceFn !== undefined) clearTimeout(delayDebounceFn);
            props.onSearch(event.target.value);
        }}
        InputProps={{
            startAdornment: <InputAdornment position="start"> <Search /></InputAdornment>
            , ...textFieldAttributes.InputProps
        }} />);
}