import React from "react";
import TabLayout, { TabLayoutItem } from "../../Layouts/TabLayout";
import { connect } from "react-redux";
import { RootState } from "../../Redux/Store";
import CanjearPuntosProductList from "./CanjearPuntosProductList";
import CheckoutCanjearPuntos from "./CheckoutCanjearPuntos";

const Tabs: TabLayoutItem[] = [
    { tabLabel: "Productos", tabId: "products", Component: CanjearPuntosProductList }
    , { tabLabel: "Envío", tabId: "envio", Component: CheckoutCanjearPuntos }
];

function CanjearPuntos() {
    return (<TabLayout
        fullHeight={false}
        tabs={Tabs}
    />);
}

export default connect(({ UserPoints }: RootState) => ({
    productList: UserPoints.productList,
    puntos_disponibles: UserPoints.resumen_puntos.disponible
}))(CanjearPuntos);