import { Chip, List, ListItem, ListItemSecondaryAction, Typography, withMobileDialog } from "@material-ui/core";
import React from "react";
import API from "../../Services/API";
import LoadingPage from "../Common/Loading";
import ErrorPage from "../Common/Error";
import Modal from "../Modal";

function RoleList(props: { roleId: string | number }) {

    const [loading, set_loading] = React.useState(false);
    const [error, set_error] = React.useState<Error | null>(null);
    const [accessList, set_accessList] = React.useState<SelectableItem[]>([]);

    React.useEffect(() => {
        if (!props.roleId) return;
        let cancel = false;
        set_loading(true);
        Promise.all([
            API.getAccessList()
            , API.getRoleAccessList(props.roleId)
        ])
            .then(([accessList, roleAcccess]) => {
                if (cancel) return;
                const roleSet = new Set(roleAcccess);
                set_accessList(
                    accessList.sort((A, B) => {
                        const groupA: any = A.grupo;
                        const groupB: any = B.grupo;
                        if (groupA > groupB) return 1;
                        if (groupA < groupB) return -1;

                        const nombreA: any = A.displayName || A.id;
                        const nombreB: any = B.displayName || B.id;
                        if (nombreA > nombreB) return 1;
                        if (nombreA < nombreB) return -1;
                        return 0;
                    }).map(a => ({
                        ...a
                        , displayName: a.displayName || a.id
                        , selected: roleSet.has(a.id)
                        , isLoading: false
                    }))
                )
                set_loading(false);
                set_error(null);
            })
            .catch(ex => {
                if (cancel) return;
                set_loading(false);
                set_error(ex);
            });

        return () => {
            cancel = true;
        }
    }, [props.roleId]);

    async function Toggle(accessId: string | number, selected: boolean) {
        const item = accessList.find(x => x.id === accessId);
        if (!item) return;
        try {
            const promise = selected
                ? API.removeRoleAccess(props.roleId, item.id)
                : API.addRoleAccess(props.roleId, item.id);

            item.isLoading = true;
            item.error = null;
            set_accessList([...accessList]);

            await promise;
            item.selected = !selected;
            item.isLoading = false;
            item.error = null;
        } catch (ex) {
            item.isLoading = false;
            item.error = ex;
        }
        set_accessList([...accessList]);
    }

    if (loading) return <LoadingPage />;
    if (error) return <ErrorPage {...error} />;


    return (<div>
        <List>
            {accessList.map(access => (<ListItem
                key={access.id}
                button
                onClick={() => Toggle(access.id, access.selected)}
                disabled={access.isLoading}
            >
                <div>
                    <Typography variant="caption">{access.grupo}</Typography>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                        <Typography>{access.displayName}</Typography>
                        {access.exponer_en_api ? "" : <Chip label="Interno" size="small" style={{ marginLeft: 10 }} />}
                    </div>
                    {access.error && <Typography color="error">{access.error?.message}</Typography>}
                </div>
                <ListItemSecondaryAction>{access.selected ? "✅" : ""}</ListItemSecondaryAction>
            </ListItem>))}
        </List>
    </div>);
}

export default withMobileDialog({
    breakpoint: "sm"
})(function RoleDialog(props: {
    roleId: string | number
    show: boolean
    fullScreen?: boolean;
    onClose(): void;
}) {
    return (<Modal
        show={props.show}
        title="Lista de accesos"
        onClose={props.onClose}
        fullScreen={props.fullScreen}
        maxWidth="md"
    >
        {props.roleId && <RoleList roleId={props.roleId} />}
    </Modal>)
});


interface SelectableItem extends Access {
    displayName: string;
    selected: boolean,
    isLoading: boolean,
    error?: Error | null
}