import React from "react";
import { Typography, TextField } from "@material-ui/core";
import { SectionHeader } from "./DetailComponents";
import ActionButtons from "./OrderActionButtons";
import ImageInput from "../ImageInput";
import ItemList from "../ItemList";
import API from "../../Services/API";


export default function EnviarAction(this: undefined, props: {
    _fechaEnvio?: string,
    onTextChange(event: React.ChangeEvent<HTMLInputElement>): void,
    loadingStatus?: string,
    src?: string,
    onImageChange(file: File): void;
    onSubmit(event: React.MouseEvent<HTMLButtonElement>): void
    obsequios?: Obsequio[]
    onObsequiosChanged?(list: Obsequio[]): void
}) {
    const { _fechaEnvio, src, onSubmit, onImageChange, onTextChange, loadingStatus } = props;

    const [productList, set_productList] = React.useState<Product[]>([]);
    React.useEffect(() => {
        const promise = API.GetProducts({
            todos: false, solo_obsequios: true, tipo_catalogo: "regular"
        });
        promise.then(data => set_productList(data));
        return () => promise.abort();
    }, []);


    const onChange = (new_item: Obsequio, add: boolean) => {
        const itemInList = props.obsequios.find(x => x.id == new_item.id);
        if (itemInList && add) return;
        if (!itemInList && !add) return;
        if (!props.onObsequiosChanged) return;

        const new_array = [...props.obsequios];
        if (add) {
            new_array.push(new_item);
            return props.onObsequiosChanged(new_array);
        }
        // if (!add) 
        new_array.splice(new_array.indexOf(itemInList), 1);
        props.onObsequiosChanged(new_array);
    }


    return (<>
        <SectionHeader>ENVIAR Y FACTURAR</SectionHeader>
        <section>
            <Typography variant="subtitle1">Agrega obsequios a este pedido:</Typography>
            <ItemList
                dataSource={productList}
                items={props.obsequios || []}
                onAdd={({ item, cantidad }) => onChange({ ...item, cantidad }, true)}
                onRemove={({ item }) => onChange(item, false)}
                IdProp="id"
                DisplayProp="nombre"
                SecondaryDisplayProp="cantidad"
                inputs={[{ type: "number", label: "Cantidad", name: "cantidad" }]}
                name="Obsequios"
            />
        </section>
        <Typography variant="button" style={{ marginRight: 10, paddingTop: 5 }} >Sube la imagen de la guía:</Typography>
        <ImageInput
            onChange={onImageChange}
            src={src}
            className=""
            title="Guía de Envío"
        />
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, marginBottom: 10 }}>
            <Typography variant="button" style={{ marginRight: 10, paddingTop: 5 }}>Fecha de Envío</Typography>
            <TextField
                type="date"
                fullWidth
                inputProps={{ id: "_fechaEnvio" }}
                name="_fechaEnvio"
                value={_fechaEnvio || ""}
                style={{ flex: 1 }}
                onChange={onTextChange}
            />
        </div>
        <ActionButtons
            loadingAction={loadingStatus}
            label="ENVIAR Y FACTURAR"
            name="ENVIAR"
            showReject={true}
            onClick={onSubmit}
        />
    </>)
}