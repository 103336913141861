import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import OrderState from "../lib/OrderState";
import RecoverStoredOrder from "../lib/RecoverStoredOrder";
import FetchOrderData from "./Thunks/FetchOrderDataThunk";
import LoadImageThunk from "./Thunks/LoadImageThunk";
import LocalStoreOrder from "./Thunks/LocalStorePedido";
import OrderSaveThunk from "./Thunks/OrderSaveThunk";

const OrderReducer = createSlice({
    name: "Order",
    reducers: {
        updateCurrentTab(state, action: PayloadAction<TabName>) {
            if (state.status !== "save-order:loading")
                state.currentTab = action.payload;
        },
        changeProductQuantity(state, action: PayloadAction<{ Id: number, type: "+" | "-" }>) {
            change_cantidad_item(state, action.payload.Id, action.payload.type);
            state.isDirty = true;
        },
        changeDataValue(state, action: PayloadAction<{ name: keyof typeof state["data"], value: any }>) {
            const { name, value } = action.payload;
            state.data[name as any] = value;
            state.isDirty = true;
        },
        changeProductPrice(state, action: PayloadAction<{ id: number, pvp_final: number }>) {
            const { pvp_final, id } = action.payload;
            var found = state.data.productList.find(x => x.id === id);
            if (found)
                found.pvp_final = pvp_final;
            state.isDirty = true;
        },
        addProductSelection(state, action: PayloadAction<{ id: number, selections: number[] }>) {
            const { id, selections } = action.payload;
            if (!state.data.productoSelecciones.hasOwnProperty(id)) {
                state.data.productoSelecciones[id] = [];
            }
            state.data.productoSelecciones[id].push(selections);
            state.isDirty = true;
        },
        removeProductSelection(state, action: PayloadAction<{ id: number, index: number }>) {
            const { id, index } = action.payload;
            if (state.data.productoSelecciones.hasOwnProperty(id)) {
                var exists = state.data.productoSelecciones[id][index];
                if (exists) state.data.productoSelecciones[id].splice(index, 1);
            }
            state.isDirty = true;
        },
        deleteImgTransaccion(state, action: PayloadAction<number>) {
            const index = action.payload;
            const item = state.data.imgTransaccionList[index];
            if (item) state.data.imgTransaccionList.splice(index, 1);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(FetchOrderData.pending, (state) => {
                state.status = "fetch-data:loading";
            })
            .addCase(FetchOrderData.rejected, (state, payload) => {
                state.status = "fetch-data:error";
                state.error = payload.error.message;
            })
            .addCase(FetchOrderData.fulfilled, (state, action) => {
                const [products, rules] = action.payload;
                state.status = "fetch-data:completed";
                state.data.productList = products.map(x => ({ ...x, cantidad: 0 })).sort((a, b) => {
                    // Primero ordenamos por categoria
                    if (a.categoria < b.categoria) return -1;
                    if (a.categoria > b.categoria) return 1;
                    
                    // Si las categorias son iguales, ordenamos por orden
                    return a.orden - b.orden;
                });
                state.giftRules = rules;
                const [updates, choosen] = RecoverStoredOrder();
                Object.assign(state.data, updates);
                for (const item of choosen) {
                    for (let i = 0; i < item.cantidad; i++) {
                        change_cantidad_item(state, item.id, "+");
                    }
                }
            })


            .addCase(LoadImageThunk.pending, state => {
                state.status = "image:loading";
            })
            .addCase(LoadImageThunk.fulfilled, (state, action) => {
                state.status = "image:completed";
                const item = {
                    src: action.payload.imgUrl
                    , file: action.payload.imgFile
                };
                const index = action.payload.index;
                if (state.data.imgTransaccionList[index]) {
                    state.data.imgTransaccionList[index] = item;
                } else {
                    state.data.imgTransaccionList.push(item);
                }

                state.isDirty = true;
            })
            .addCase(LoadImageThunk.rejected, (state, error) => {
                state.status = "image:error";
                state.error = error.error.message;


            }).addCase(LocalStoreOrder.fulfilled, (state) => {
                state.isDirty = false;
            })


            .addCase(OrderSaveThunk.pending, (state) => {
                state.status = "save-order:loading";
            })
            .addCase(OrderSaveThunk.rejected, (state, action) => {
                state.status = "save-order:error";
                state.error = action.error.message;
                if (action.error.name === "OrderDataValidationError") {
                    state.currentTab = "OrderData";
                }
                if (action.error.name === "ProductsValidationError") {
                    state.currentTab = "ProductList";
                }
            })
            .addCase(OrderSaveThunk.fulfilled, (state) => {
                state.isDirty = false;
                state.status = "save-order:completed";
                const [updates] = RecoverStoredOrder();
                state.data = Object.assign({}, new OrderState(), updates, {
                    productList: state.data.productList.map(x => ({ ...x, cantidad: 0 }))
                });
                state.currentTab = "ProductList";
            });
    },
    initialState: {
        currentTab: "ProductList" as TabName
        , isDirty: false
        , status: "" as "" |
            "fetch-data:loading" | "fetch-data:completed" | "fetch-data:error" |
            "save-order:loading" | "save-order:completed" | "save-order:error" |
            "image:loading" | "image:completed" | "image:error"
        , error: undefined as string
        , data: Object.assign({}, new OrderState())
        , giftRules: [] as GiftRule[]
    }
});



function change_cantidad_item(state: ReturnType<typeof OrderReducer["getInitialState"]>, Id: number, type: "+" | "-") {
    var item = state.data.productList.find(x => x.id === Id);
    if (!item) return;
    var previous_cant = (item.cantidad && !isNaN(+item.cantidad)) ? item.cantidad : 0;
    if (type === "+") {
        if (item.cantidad_desde && previous_cant <= 0) {
            item.cantidad = item.cantidad_desde;
        } else {
            item.cantidad = (+previous_cant) + 1;
        }
    }
    if (type === "-") {
        if (item.cantidad_desde && previous_cant <= item.cantidad_desde) {
            item.cantidad = 0
        } else {
            item.cantidad = (+previous_cant) - 1;
        }
    }
}

export default OrderReducer.reducer;
export type OrderReduceState = ReturnType<typeof OrderReducer["getInitialState"]>
export const { actions } = OrderReducer;
export type TabName = "ProductList" | "OrderData" | "ConfirmOrder" | "OrderPayment";