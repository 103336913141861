import React from "react";
import Input from "../Input";
import Modal from "../Modal";
import API from "../../Services/API";
import ImageInput from "../ImageInput";
import ImageReader, { ImgFileToObject } from "../../Utils/ImageReader";
import { Button, Typography, FormControlLabel, Switch, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip } from "@material-ui/core";
import ItemList from "../ItemList";
import { Focus } from "../../lib/DOMActions";
import { HelpOutline } from "@material-ui/icons";

interface Props {
    onDone: (update?: boolean) => void,
    showingID: string,
    tipo_catalogo: "premios" | "regular",
    productList: FullProduct[]
}
interface State extends FullProduct {
    error?: { [name: string]: string }
    loading?: string;
    rawFile?: File;
}

export default class Edit extends React.Component<Props, State> {
    state = {} as State;
    handleChange = e => {
        const { name, value } = e.currentTarget;
        this.setState({ [name]: value } as any);
    }
    handleChecked = e => {
        const { name, checked } = e.currentTarget;
        this.setState({ [name]: checked } as any);
    }
    handleDelete = () => {
        if (!this.state.id) return;
        var doIt = window.confirm("Deseas continuar con esta acción?");
        if (!doIt) return;
        this.setState({ loading: "ELIMINAR" });
        API.DeleteProduct(this.state.id)
            .then(this._updated)
            .catch(this._failed);
    }
    handleSave = async () => {
        try {
            const errors = this._validar(this.state);
            if (errors) return Focus(Object.keys(errors)[0]);

            this.setState({ loading: "GUARDAR" });
            if (this.state.rawFile)
                var imageFile = await ImgFileToObject(this.state.rawFile)

            await API.SaveProduct({ ...this.state, imageFile, tipo_catalogo: this.props.tipo_catalogo })
            this._updated();
        } catch (ex) {
            this._failed(ex)
        }
    }
    handleStock = async () => {
        try {
            var stockInput = window.prompt("Digita la cantidad de stock a agregar");
            if (stockInput === null || stockInput === undefined) return;
            if (isNaN(parseInt(stockInput))) throw new Error("Digita una cantidad mayor a 0");

            if (this.state.tipo_catalogo !== "premios") throw new Error("Esta función solo está disponible para `premios`");

            this.setState({
                loading: "STOCK"
            });
            await API.AddStock({
                id: this.state.id
                , tipo_catalogo: this.state.tipo_catalogo as "premios"
                , cantidad: +stockInput
            });
            this.setState({
                loading: ""
            });
            window.alert("El stock se ha actualizado correctamente.");
        } catch (ex) {
            this._failed(ex);
        }
    }
    _validar(data: FullProduct) {
        var nombre = (data.nombre || "").trim();
        const errorSet: any = {};
        if (!nombre) errorSet.nombre = "Digita el nombre";
        
        var categoria = (data.categoria || "").trim();
        if (!categoria) errorSet.categoria = "Digita la categoria";

        const { tipo_catalogo } = this.props;

        if (tipo_catalogo === "regular") {
            const pvp_final = numberOrEmpty(data.pvp_final);
            if (!pvp_final.toString().trim()) {
                errorSet.pvp_final = "Digita el Precio Final";
            } else {
                if (isNaN(+data.pvp_final)) {
                    errorSet.pvp_final = "Precio final debe ser un número";
                } else if (data.combo) {
                    const total_componentes = (data.componentes || []).reduce<number>((a, b) => {
                        return +(a + calculo_componente(this.props.productList.find(x => x.id == b.idComponente), b)[2]).toFixed(2);
                    }, 0);
                    if (total_componentes != pvp_final) {
                        errorSet.pvp_final = `El valor de los componentes (${total_componentes.toCurrency()}) debe ser el mismo que el precio del combo.`;
                    }
                }
            }
            if (data.opciones && data.opciones.length && !data.maxItems)
                errorSet.maxItems = "Digita la cantidad máxima a escoger";
        } else if (tipo_catalogo === "premios") {
            const puntosPrecio = numberOrEmpty(data.puntosPrecio);
            if (!puntosPrecio.toString().trim()) {
                errorSet.puntosPrecio = "Digita los Puntos para Canje";
            } else {
                if (isNaN(+data.puntosPrecio)) {
                    errorSet.puntosPrecio = "Puntos para Canje debe ser un número";
                }
            }
        } else {
            console.error("Product._validate Not Implemented", data);
            throw new Error("Not Implemented");
        }

        if (!(data.sku && data.sku.toString().trim().length)) errorSet.sku = "Digita el SKU (codigo)";
        if (!(data.orden && data.orden.toString().trim().length)) errorSet.orden = "Digita el orden en que aparecerá el producto";

        if (data.descripcion_url && data.descripcion_url.trim().length > 300)
            errorSet.descripcion_url = `El enlace de la descripción solo puede tener hasta 300 caracteres. Actualmente tiene ${data.descripcion_url.trim().length}`

        if (Object.keys(errorSet).length) return errorSet;
        return;
    }
    _updated = () => {
        this.setState({ loading: null, error: null });
        this.props.onDone(true);
    }
    _failed = error => {
        this.setState({ loading: null, error });
        window.requestAnimationFrame(() => {
            try { document.getElementById("ERROR_MESSAGE").focus(); } catch (ex) { }
        });
    }
    handleClose = () => {
        if (this.state.loading) return;
        this.props.onDone();
    }
    handleFile = rawFile => {
        ImageReader(rawFile)
            .then(imgSrc => this.setState({ imgSrc, rawFile }))
            .catch(this._failed);
    }

    handleAdd = ({ name, item, porcentaje_descuento_contifico, cantidad }) => {
        this.setState(prev => {
            const state = { ...prev };
            if (name === "OPCIONES") {
                state.opciones = state.opciones ? [...state.opciones] : [];
                state.opciones.push({ idOpcion: +item.id });
                return state;
            }
            if (name === "COMPONENTES") {
                state.componentes = state.componentes ? [...state.componentes] : [];
                state.componentes.push({ idComponente: +item.id, cantidad: +(cantidad || "1"), porcentaje_descuento_contifico });
                return state;
            }
        });
    }
    handleRemove = ({ name, index }) => {
        this.setState(prev => {
            const state = { ...prev };
            if (name === "OPCIONES") {
                state.opciones = [...state.opciones];
                state.opciones.splice(index, 1);
            }
            if (name === "COMPONENTES") {
                state.componentes = [...state.componentes];
                state.componentes.splice(index, 1);
            }
            return state;
        });
    }

    componentWillReceiveProps(nextProps) {
        const id = this.props.showingID;
        const nextId = nextProps.showingID;
        var show = !(id === null || id === undefined);
        var nextShow = !(nextId === null || nextId === undefined);
        if (!show && nextShow) {
            this.setState(() => {
                var { habilitado, ...exists } = this.props.productList.find(p => p.id === +nextId) || {} as any;
                const keys = Object.keys(this.state);
                // RESETEO LAS PROPIEDADES QUE NO EXISTEN
                if (habilitado === undefined || habilitado === null) habilitado = true;
                return {
                    ...keys.reduce((acc, v) => ({ ...acc, [v]: undefined }), {})
                    , ...exists
                    , habilitado
                }
            });
        }
    }

    // MIENTRAS ESTÉ CERRADO, EL COMPONENTE NO SE ACTUALIZARÁ
    shouldComponentUpdate(nextProps) {
        var isOpen = !(this.props.showingID === null || this.props.showingID === undefined);
        var NextisOpen = !(nextProps.showingID === null || nextProps.showingID === undefined);
        return (isOpen === NextisOpen && isOpen === false) ? false : true;
    }
    render() {
        const { loading, error: storedError } = this.state;
        const id = this.props.showingID;
        const error: { [key: string]: any } = Object.assign({}, this._validar(this.state) || {}, storedError || {});
        const show = !(id === null || id === undefined);

        const opciones = (this.state.opciones || []).map(o => ({
            id: o.idOpcion
            , nombre: (this.props.productList.find(a => a.id === o.idOpcion) || {}).nombre
        }));
        const componentes = (this.state.componentes || []).map(o => ({
            id: o.idComponente
            , nombre: labelComponente(this.props.productList, o)
            , cantidad: o.cantidad
        }));
        const dataSource = this.props.productList.filter(x => !x.combo && x.id !== +id);

        const esPremio = this.props.tipo_catalogo === "premios";

        const footer = (<>
            <FormControlLabel
                label="Habilitado"
                style={{ flexGrow: 1 }}
                control={<Switch
                    checked={!!this.state.habilitado}
                    name="habilitado"
                    onChange={this.handleChecked}
                />}
            />
            {id === "NUEVO"
                ? null
                : (<>
                    <Button disabled={!!loading} onClick={this.handleDelete} color="secondary" variant="outlined" >
                        {loading === "ELIMINAR" ? "ELIMINANDO..." : "ELIMINAR"}
                    </Button>
                    {this.state.tipo_catalogo === "premios"
                        ? (<Button disabled={!!loading} onClick={this.handleStock} color="primary" variant="outlined">
                            {loading === "STOCK" ? "GUARDANDO..." : "AGREGAR STOCK"}
                        </Button>)
                        : null}
                </>)}
            <Button disabled={!!loading} onClick={this.handleSave} color="primary" variant="outlined" >
                {loading === "GUARDAR" ? "GUARDANDO..." : "GUARDAR"}
            </Button>

            <Button color="inherit" disabled={!!loading} onClick={this.handleClose}>SALIR</Button>
        </>);

        return (<Modal
            title=""
            maxWidth="md"
            onClose={this.handleClose}
            show={show}
            footer={footer}

        >
            <Input
                value={this.state.nombre || ""}
                onChange={this.handleChange}
                name="nombre"
                required
                label="Nombre"
                maxLength={100}
                error={error.nombre}
            />
            <Input
                value={this.state.categoria || ""}
                onChange={this.handleChange}
                name="categoria"
                required
                label="Categoria"
                maxLength={100}
                error={error.categoria}
            />
            <ImageInput
                className="card-product-img"
                onChange={this.handleFile}
                src={this.state.imgSrc}
                title={this.state.nombre}
            />
            <div style={{ textAlign: "center", marginBottom: 10 }}>
                <Typography variant="caption">
                    El peso recomendado para la imagen es entre 100 y 200 Kbs.
                </Typography>
            </div>

            <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", display: "flex" }}>
                <Input type="phone"
                    value={numberOrEmpty(this.state.pvp_final)}
                    onChange={this.handleChange}
                    name="pvp_final"
                    required
                    label="Precio Final"
                    maxLength={10}
                    error={error.pvp_final}
                    style={{ minWidth: 100, flex: 1, marginTop: 0, paddingLeft: 5, paddingRight: 5, margin: "auto" }}
                />
                <Input type="phone"
                    value={numberOrEmpty(this.state.cantidad_desde)}
                    onChange={this.handleChange}
                    name="cantidad_desde"
                    label="Cantidad mínima"
                    maxLength={2}
                    error={error.cantidad_desde}
                    style={{ minWidth: 100, flex: 1, marginTop: 0, paddingLeft: 5, paddingRight: 5, margin: "auto" }}
                />
                <Input type="phone"
                    value={numberOrEmpty(this.state.puntos)}
                    onChange={this.handleChange}
                    name="puntos"
                    label="Puntos Acumulua"
                    error={error.puntos}
                    maxLength={10}
                    style={{ minWidth: 100, marginTop: 0, flex: 1, paddingLeft: 5, paddingRight: 5, margin: "auto" }}
                />
            </div>


            <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", display: esPremio ? "default" : "none" }}>
                <Input type="phone"
                    value={numberOrEmpty(this.state.puntosPrecio)}
                    onChange={this.handleChange}
                    name="puntosPrecio"
                    required
                    label="Puntos para canjear"
                    maxLength={10}
                    error={error.puntosPrecio}
                    style={{ marginRight: 10 }}
                />
            </div>

            <Input
                type="url"
                value={this.state.descripcion_url || ""}
                onChange={this.handleChange}
                name="descripcion_url"
                required
                label="Descripción"
                maxLength={300}
            />

            <div style={{ paddingTop: 20, margin: "auto", display: "flex", flexDirection: "row" }} >
                <Input
                    style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
                    value={this.state.sku || "" as any}
                    onChange={this.handleChange}
                    name="sku"
                    required
                    label="SKU"
                    maxLength={20}
                    error={error.sku}
                    InputProps={{
                        endAdornment: <SKUWarning {...this.state} />
                    }}
                />
                <Input
                    onChange={this.handleChange}
                    value={this.state.orden || "" as any}
                    style={{ width: 100, marginLeft: 10 }}
                    name="orden"
                    required
                    label="Orden"
                    maxLength={5}
                    error={error.orden}
                />
            </div>

            <div style={{ paddingTop: 20, margin: "auto", display: esPremio ? "none" : "flex", flexDirection: "row" }} >
                <FormControlLabel
                    label="Aplica a comisión"
                    control={<Switch
                        checked={!!this.state.aplica_comision}
                        name="aplica_comision"
                        onChange={this.handleChecked}
                    />}
                />
                <FormControlLabel
                    label="Aplica a Descuento"
                    control={<Switch
                        checked={!!this.state.aplica_descuento}
                        name="aplica_descuento"
                        onChange={this.handleChecked}
                    />}
                />
                <FormControlLabel
                    label="Es Promoción"
                    title="Ciertos roles (como Natu PLUS) no tienen acceso a promociones."
                    control={<Switch
                        checked={!!this.state.es_promocion}
                        name="es_promocion"
                        onChange={this.handleChecked}
                    />}
                />
                <FormControlLabel
                    label="Obsequio"
                    title="Cuando esta opción esté habilitada, el item aparecerá en la lista de obsequios."
                    control={<Switch
                        checked={!!this.state.es_obsequio}
                        name="es_obsequio"
                        onChange={this.handleChecked}
                    />}
                />
            </div>

            <ExpansionPanel expanded={!!this.state.combo} style={{ display: esPremio ? "none" : "default", marginTop: 20 }} >
                <ExpansionPanelSummary>
                    <FormControlLabel
                        label="El producto es un combo"
                        control={<Switch
                            checked={!!this.state.combo}
                            name="combo"
                            onChange={this.handleChecked}
                        />}
                    />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }} >
                    <Typography style={{ marginTop: 10 }} variant="h6">COMPONENTES</Typography>
                    <ItemList
                        dataSource={dataSource}
                        items={componentes}
                        onAdd={this.handleAdd}
                        onRemove={this.handleRemove}
                        IdProp="id"
                        DisplayProp="nombre"
                        SecondaryDisplayProp="cantidad"
                        inputs={[{ type: "number", label: "% Desc", name: "porcentaje_descuento_contifico" }, { type: "number", label: "Cantidad", name: "cantidad" }]}
                        name="COMPONENTES"
                    />

                    <Typography style={{ marginTop: 20 }} variant="h6">OPCIONES</Typography>
                    <ItemList
                        dataSource={dataSource}
                        items={opciones}
                        onAdd={this.handleAdd}
                        onRemove={this.handleRemove}
                        inputs={[]}
                        IdProp="id"
                        DisplayProp="nombre"
                        name="OPCIONES"
                    />
                    <Input type="phone"
                        value={this.state.maxItems || "" as any}
                        onChange={this.handleChange}
                        name="maxItems"
                        label="Máximo"
                        maxLength={2}
                        error={error.maxItems}
                        style={{}}
                    />

                </ExpansionPanelDetails>
            </ExpansionPanel>
            <Typography variant="body2" color="secondary" id="ERROR_MESSAGE" tabIndex={0} style={{ marginTop: 10 }}>
                {this.state.error && this.state.error.message}
            </Typography>

        </Modal>);
    }
}

function numberOrEmpty(value?: number | null) {
    if (value === null || value === undefined) return "";
    return value as any;
}


function SKUWarning(props: {
    contifico_id?: string
    , sku_warning?: string
}) {
    if (props.sku_warning)
        return (<Tooltip title={props.sku_warning}>
            <HelpOutline
                style={{ height: "1em", zIndex: 1, cursor: "pointer" }}
            />
        </Tooltip>);

    if (props.contifico_id)
        return (<Tooltip title="Producto conectado al sistema contable">
            <Typography variant="button">✅</Typography>
        </Tooltip>);
    return null;
}


function labelComponente(productList: FullProduct[], o: FullProduct["componentes"][number]) {
    const product = productList.find(a => a.id === o.idComponente);
    if (!product) return "❌ (item no encontrado)";

    const [, , valor_final] = calculo_componente(product, o);
    return [product.nombre
        , `(${product.pvp_final.toCurrency()} / ${(o.porcentaje_descuento_contifico || 0)}% desc.)`
        , "-"
        , valor_final.toCurrency()].join(" ");
}

function calculo_componente(product: FullProduct | null | undefined, o: FullProduct["componentes"][number]) {
    if (!product) return [0, 0, 0];
    if (!o.cantidad) return [0, 0, 0];

    const total_sin_descuento = +(product.pvp_final * o.cantidad).toFixed(2);
    const descuento_valor = o.porcentaje_descuento_contifico
        ? +((total_sin_descuento * o.porcentaje_descuento_contifico) / 100).toFixed(2)
        : 0;
    const valor_final = +(total_sin_descuento - descuento_valor).toFixed(2)
    return [total_sin_descuento, descuento_valor, valor_final];
}