import React from "react";
import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";


const useStyles = makeStyles({
    AppBar: {
        bottom: 0,
        left: 0,
        top: "auto"
    },
    title: {
        flexGrow: 1,
        textAlign: "right"
        , "& > *": {
            display: "inline-block"
        }
    }
});
export default function BottomAppBar(props: {
    disponible: number
    , continuarSiguienteSeccion?: boolean
}) {
    const classes = useStyles();
    return (<AppBar color={props.continuarSiguienteSeccion ? "primary" : "secondary"} className={classes.AppBar} elevation={24}>
        <Toolbar variant="dense">
            <div className={classes.title}>
                {props.continuarSiguienteSeccion
                    ? <Typography style={{marginRight:"1rem"}} >Listo! Continúa a la siguiente sección</Typography>
                    : null}
                <Typography variant="h6" color="inherit">
                    Disponible: {props.disponible}
                </Typography>
            </div>
        </Toolbar>
    </AppBar>);
}