import React from "react";
import { Dialog, DialogContent, AppBar, Toolbar, Button, Typography, IconButton, DialogActions, DialogTitle } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import { Close } from "@material-ui/icons";

const Transition: any = React.forwardRef(function (props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
} as any);

interface ModalProps {
    show: boolean;
    title: React.ReactNode;
    onClose?: (event: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    fullScreen?: boolean;
    maxWidth?: React.ComponentProps<typeof Dialog>["maxWidth"];
    closeButtonText?: string | "ICON";
    children?: any;
    footer?: any;
    classes?: {
        dialogContent?: {
            root?: string
        }
    }
}

export default function Modal(props: ModalProps) {
    const { show, onClose, fullScreen, maxWidth, title } = props;
    var closeButtonText = props.closeButtonText || "ICON";

    return (<Dialog
        maxWidth={maxWidth || (fullScreen ? false : "sm")}
        fullWidth={true}
        open={show}
        onClose={onClose}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
    >
        {fullScreen
            ? (<AppBar position="relative">
                <Toolbar>
                    {typeof title === typeof ""
                        ? <Typography color="inherit" variant="h4" style={{ flexGrow: 1 }}>{title}</Typography>
                        : title}
                    {closeButtonText === "ICON"
                        ? (<IconButton onClick={onClose} type="button" color="inherit">
                            <Close />
                        </IconButton>)
                        : (<Button type="button" onClick={onClose} color="inherit">
                            {closeButtonText || "SALIR"}
                        </Button>)
                    }
                </Toolbar>
            </AppBar>)
            : (typeof title === typeof ""
                ? <DialogTitle>{title as string}</DialogTitle>
                : title)}

        <DialogContent classes={{ root: props?.classes?.dialogContent?.root }} >
            {props.children}
        </DialogContent>

        {props.footer
            && <DialogActions>
                {props.footer}
            </DialogActions>}
    </Dialog>)
}