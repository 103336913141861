import React from "react";
import TabLayout, { TabLayoutItem } from "../../Layouts/TabLayout";
import { connect } from "react-redux";
import { StatusType } from "./Redux/UserPointsReducer";
import FetchInitialDataThunk from "./Redux/Thunks/UserPointsFetchInitialState";
import { RootState } from "../../Redux/Store";
import { Typography, CircularProgress } from "@material-ui/core";
import MisPuntos from "./MisPuntos";
import CanjearPuntos from "./CanjearPuntos";
import { match } from "react-router-dom";

const Tabs: TabLayoutItem[] = [
    { Component: MisPuntos, tabLabel: "Mis Puntos", tabId: "/resume" }
    , { Component: CanjearPuntos, tabLabel: "Canjear Puntos", tabId: "/points" }
];

function UserPointsScreen(props: {
    onMounted(path: string): void;
    userId: number;
    match: match;
    status: StatusType,
    error?: Error,
    getInitialData(userId): void
}) {

    React.useEffect(() => {
        props.onMounted && props.match && props.onMounted(props.match.path);
        props.getInitialData(props.userId);
    }, []);

    React.useEffect(() => {
        if (props.status !== "SUBMITTED") return;
        window.alert("Guardado con éxito!");
        props.getInitialData(props.userId);
    }, [props.status])


    if (props.status === "INITIALIZING") {
        return (<div style={{ marginTop: "20vh", textAlign: "center", margin: "auto" }}>
            <CircularProgress size={50} color="primary" style={{ margin: "auto" }} />
        </div>);
    }
    if (props.status === "INITIALIZATION_ERROR") {
        return (<div style={{ paddingTop: "20vh", textAlign: "center", maxWidth: 500 }}>
            <Typography variant="h6">{props.error?.message || "Ocurrió un error. Intentalo nuevamente."} Cambia de pantalla y regresa para actualizar.</Typography>
        </div>);
    }
    return <TabLayout
        tabs={Tabs}
    />
}

export default connect((state: RootState) => ({
    status: state.UserPoints.status,
    error: state.UserPoints.error
}), {
    getInitialData: FetchInitialDataThunk
})(UserPointsScreen);
