import { CircularProgress, Typography, Button, Paper, makeStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";
import { OrderReduceState } from "../Order/Redux/OrderReducer";
import ProductGrid from "./ProductGrid";
import SearchInput from "../Common/SearchInput";


const useStyles = makeStyles({
    details: {
        marginTop: 10
        , border: "1px solid black"
        , borderRadius: 10
        , padding: 20
    }
    , container: {
        display: "flex"
        , flexDirection: "column"
        , minHeight: 1
        , textAlign: "left"
    }
    , groupList: {
        flex: 1,
        overflow: "auto"
    }
});

export default function ProductGridContainer(props: {
    items: FullProduct[];

    onActionClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    editablePrices?: boolean;
    onPriceChange?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;

    controls?: "ADD-REMOVE";
    onCardClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    showNewItem?: boolean;
    async_status?: OrderReduceState["status"]
    async_error?: string;
    classes?: {
        groupList?: string
        container?: string
    }
}) {
    const [search, set_search] = React.useState("");
    const classes = useStyles();

    if (props.async_status === "fetch-data:loading")
        return (<div style={{ width: "100%", textAlign: "center", paddingTop: 100 }} >
            <CircularProgress size={100} />
            <Typography variant="h6">CARGANDO...</Typography>
        </div>);



    if (props.async_status === "fetch-data:error")
        return (<div style={{ width: "100%", textAlign: "center", paddingTop: 100 }} >
            <Typography variant="h4">Ocurrió un error</Typography>
            {props.async_error && <Typography variant="h4">{props.async_error}</Typography>}
        </div>);

    const Grupos = ProductosToGrupos(props.items, x => searchMatch(search, x));
    if (props.async_status === "fetch-data:completed" && !Grupos.length /*!visible_items.length*/) var ThereIsNotProducts = true;

    return (<div className={[props.classes?.container || "", classes.container].join(" ")}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "normal", paddingTop: 10, paddingBottom: 5, gap: "1em" }}>
            <Paper style={{ flex: 1 }}>
                <SearchInput onSearch={value => set_search(value)} />
            </Paper>

            {props.showNewItem && (<Button
                id="NUEVO"
                value="NUEVO"
                variant="outlined"
                onClick={props.onCardClick}
            >
                <Add /> Nuevo
            </Button>)}
        </div>
        {
            !ThereIsNotProducts ? null : (<div style={{ width: "100%", textAlign: "center", paddingTop: 100 }}>
                <Typography variant="h6">No hay productos</Typography>
            </div>)
        }
        <div className={[props.classes?.groupList || "", classes.groupList].join(" ")}>
            {Grupos.map(g => (
                <details
                    key={g.key}
                    open={!!search}
                    className={classes.details}
                >
                    <summary className="MuiButton-root MuiButton-outlined">{g.key}</summary>
                    <ProductGrid
                        Products={g.productos}
                        onActionClick={props.onActionClick}
                        editablePrices={props.editablePrices}
                        onPriceChange={props.onPriceChange}
                        controls={props.controls}
                        onCardClick={props.onCardClick}
                    />
                </details>
            ))}
        </div>
    </div>)
}


function ProductosToGrupos(items: Product[], filter: Function) {

    const grupos_dict: { [key: string]: Grupo } = {};
    const grupos = items.reduce((a, x) => {

        if (!filter(x)) return a;

        var grupo = grupos_dict[x.categoria];
        if (!grupo) {
            grupo = {
                key: x.categoria
                , productos: []
            };
            a.push(grupo);
            grupos_dict[x.categoria] = grupo;
        }
        grupo.productos.push(x);
        return a;
    }, [] as Grupo[]);

    return grupos.sort((item1, item2) => {
        let a: any = item1.key;
        let b: any = item2.key;
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    });
}

function searchMatch(search: string, x: Product) {
    if (!search) return true;
    if (x.nombre.toUpperCase().trim().indexOf((search || "").toUpperCase().trim()) >= 0) {
        return true;
    }
    if (x.categoria.toUpperCase().trim().indexOf((search || "").toUpperCase().trim()) >= 0) {
        return true;
    }
    return false;
}
interface Grupo {
    key: string
    , productos: Product[] | FullProduct[]
}