import { IconButton, List, ListItem, ListItemSecondaryAction, Typography } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import React from "react";
import RegisterPayment from "../Payment/RegisterPayment";

export default function (props: {
    onChange(new_value: SavePayment[]): void;
    value?: SavePayment[]
}) {
    var pagos = props.value || [];

    const deleteItem = function (item) {
        var index = pagos.indexOf(item);
        const new_array = [...pagos];
        new_array.splice(index, 1);
        props.onChange(new_array);
    }
    const changeValue = function (name: string, value: string, item: SavePayment) {
        var index = pagos.indexOf(item);
        const new_pago = { ...item, [name]: value };
        const new_array = [...pagos];
        new_array[index] = new_pago;
        props.onChange(new_array);
    }
    const addItem = function () {
        props.onChange([...pagos, {
            metodoPago: "",
            monto: "",
            numeroTransaccion: "",
        }])
    }

    return (<List>
        <ListItem button onClick={() => addItem()} >AGREGAR PAGO <Add /></ListItem>
        {pagos.map((item, index) => {
            return (<ListItem key={index}>
                <div style={{ flex: 1 }}>
                    <fieldset>
                        <legend>
                            <div style={{display:"flex",flexDirection:"row", alignItems:"center"}}>
                                <IconButton onClick={() => deleteItem(item)} type="button">
                                    <Close />
                                </IconButton>
                                <Typography variant="button">Pago #{index+1}</Typography>
                            </div>
                        </legend>
                        <RegisterPayment
                            {...item}
                            add_to_id={"" + index}
                            onChange={(propName, value) => changeValue(propName, value, item)}
                        />
                    </fieldset>
                </div>
            </ListItem>)
        })}
    </List>)
}