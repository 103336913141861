/// <reference path="../../shared/Payment.d.ts"/>
import React from "react";
import { Dialog, DialogContent, DialogTitle, Typography, TextField, Button, CircularProgress, DialogActions, CardMedia } from "@material-ui/core";
import ConfirmOrder from "../ConfirmOrder";
import API from "../../Services/API";
import { DocToString } from "../../Utils/Calcs";
import OrderActions from "../../Services/OrderActions";
import { SectionHeader, TextRow } from "./DetailComponents";
import PagoSection from "./PagoSection";
import { BugReport, Redeem } from "@material-ui/icons";
import RegistroMultiPago from "./RegistroMultiPago";
import EnviarAction from "./EnviarAction";
import ActionButtons from "./OrderActionButtons";
import ImageReader from "../../Utils/ImageReader";
import ExpandImage from "../../lib/ExpandImage";
import PremioList from "./PremioList";
import ContificoDocInfo from "./ContificoDocInfo";

export default class detaOrderDetails extends React.Component<{
    onClose: (refresh: boolean) => void,
    editable?: boolean,
    id: number
}> {
    state = {
        loading: "data",
        _fechaEnvio: "",
        _fechaRecepcion: "",
        _pagos: [],
        _obsequios: [],
        urls: undefined
    } as OrderDetailsState
    componentWillMount() {
        API.GetOrder(this.props.id)
            .then(order => this.setState({ ...order, loading: null }))
            .then(() => {
                API.GetOrderInvoiceUrl(this.props.id)
                    .then(urls => this.setState({
                        urls: {
                            ...urls,
                            total: urls.total && parseFloat(urls.total.toString())
                        }
                    }))
                    .catch(() => this.setState({ url: null }));
            })
            .catch(ex => this.setState({ loading: "data-error", data_error_message: ex.message }));
    }
    handleClose = (_: React.MouseEvent<HTMLElement>) => {
        this.props.onClose(this.state.shouldRefresh);
    }
    handleButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { name } = event.currentTarget;
        if (this.state.loading) return;
        if (name === "APROBAR") {
            let promise = OrderActions.Aprobar(this.state);
            if (!promise) return;
            promise.then(x => this.props.onClose(true))
                .catch(ex => {
                    alert(ex.message);
                    this.setState({ loading: null });
                });
        }
        if (name === "RECHAZAR") {
            let promise = OrderActions.Rechazar(this.state);
            if (!promise) return;
            promise.then(x => this.props.onClose(true))
                .catch(ex => {
                    alert(ex.message);
                    this.setState({ loading: null });
                });
        }
        if (name === "ENVIAR") {
            let promise = OrderActions.Enviar(this.state);
            if (!promise) return;
            promise.then(x => this.props.onClose(true))
                .catch(ex => {
                    alert(ex.message);
                    this.setState({ loading: null });
                });
        }
        if (name === "FACTURAR") {
            let promise = OrderActions.Facturar(this.state);
            if (!promise) return;
            promise.then(x => this.props.onClose(true))
                .catch(ex => {
                    alert(ex.message);
                    this.setState({ loading: null });
                });
        }
        this.setState({ loading: name });
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;
        this.setState({ [name]: value });
    }
    handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.currentTarget;
        this.setState({ [name]: checked });
    }
    handleEnvioImg = (file: File) => {
        this.setState({ _envioImgSrcStatus: "loading" });
        if (this.state._envioImgSrcStatus) return;
        ImageReader(file)
            .then(src => {
                this.setState({
                    _envioImgSrc: src,
                    _envioImgFile: file,
                    _envioImgSrcStatus: null
                })
            }).catch(ex => {
                alert("No se pudo cargar la imagen:" + ex.message);
            });
    }
    handleObsequiosChange = (_obsequios: Obsequio[]) => {
        this.setState({ _obsequios });
    }
    handlePagos = _pagos => {
        this.setState({ ...this.state, _pagos });
    }
    render() {
        const { numero,
            data_error_message,
            fechaCreacion, fechaRecepcion,
            fechaEnvio, envioImgSrc,
            detalle, premios, puntosAcumulados,
            descuentoPorcentaje, descuentoTotal, observacion,
            direccion, sector, ciudad, referencia, tipo_envio,
            estado, total, envio, username, comision_aplicada,
            survey1, survey2, loading,
            documento, identificacion, razonSocial,
            telefono_factura, direccion_factura, email_factura,
            nombre_contacto, identificacion_contacto, documento_contacto, telefono_contacto,
            numeroFactura, /*, _fechaRecepcion*/ bienRecibido, observacionRecepcion,
            urls
        } = this.state;

        const { subtotal_con_descuento, subtotal } = (detalle || [])
            .reduce((results, b) => {
                if (b.idReglaRegalo) return results;
                const subtotal = (b.cantidad * b.pvp_final);
                if (b.descuentoTotal)
                    results.subtotal_con_descuento = (subtotal + results.subtotal_con_descuento).moneyRound();
                results.subtotal = (subtotal + results.subtotal).moneyRound();
                return results;
            }, {
                subtotal_con_descuento: 0, subtotal: 0
            });

        const editable = this.props.editable === true;

        const total_pedido = (total - envio).moneyRound();
        const gran_total = (total - comision_aplicada).moneyRound();

        if (loading === "data" || loading === "data-error")
            return (<Dialog open={true} onClose={this.handleClose} maxWidth="md" >
                <DialogContent style={{ textAlign: "center", paddingTop: 100, width: 400, height: 400 }}>
                    {loading === "data"
                        ? <>
                            <CircularProgress size={100} />
                            <Typography variant="h6">CARGANDO...</Typography>
                        </>
                        : null}

                    {loading === "data-error"
                        ? <>
                            <BugReport color="secondary" />
                            <Typography color="secondary" variant="h6">{data_error_message}</Typography>
                        </>
                        : null}
                </DialogContent>
            </Dialog>);

        const URL_Info = urls === undefined
            ? <CircularProgress color="primary" size="1em" />
            : <ContificoDocInfo {...(urls || {})} gran_total={gran_total} />;

        return (<Dialog open={true} onClose={this.handleClose} maxWidth="md">
            <DialogTitle>PEDIDO #{numero}</DialogTitle>
            <DialogContent>
                <TextRow value={new Date(fechaCreacion).toLocaleString()} label="Fecha" />
                <TextRow value={username} label="Usuario" />
                <TextRow value={estado} label="Estado" />
                <hr />
                <TextRow value={(puntosAcumulados || 0)} label={<>Puntos <Redeem style={{ fontSize: "1em", verticalAlign: "text-bottom" }} /></>} />
                <hr />

                {numeroFactura && <TextRow value={<>{numeroFactura}&nbsp;&nbsp;{URL_Info}</>} label="Comprobante #" />}
                {fechaEnvio && <TextRow value={new Date(fechaEnvio).toLocaleDateString()} label="Enviado" />}
                {envioImgSrc && <CardMedia
                    component="img"
                    style={{ height: "auto", width: "calc(100% - 20px)", objectFit: "contain", border: "1px solid black", margin: 10 }}
                    image={envioImgSrc}
                    title="Guia de Envio"
                    onClick={() => ExpandImage(envioImgSrc)}
                />}
                {fechaRecepcion && <TextRow value={new Date(fechaRecepcion).toLocaleDateString()} label="Recibido" />}
                {estado === "RECIBIDO" && <TextRow value={(bienRecibido ? "NO" : "SI")} label="Incompleto" />}
                {estado === "RECIBIDO" && <TextRow label="Comentarios" value={observacionRecepcion} />}

                <SectionHeader>PRODUCTOS</SectionHeader>
                <ConfirmOrder
                    items={detalle}
                    descuento={{ porcentaje: descuentoPorcentaje, valor: descuentoTotal }}
                    valor_envio={envio}
                    total_pedido={total_pedido}
                    gran_total={gran_total}
                    subtotal={subtotal}
                    subtotal_con_descuento={subtotal_con_descuento}
                    showComponentes={true}
                />
                <TextRow value={observacion} label="Observación" />

                <SectionHeader>PREMIOS A RECIBIR</SectionHeader>
                <PremioList items={premios || []} />

                <SectionHeader>DATOS DE ENTREGA</SectionHeader>

                <TextRow value={nombre_contacto} label="Nombre" />
                <TextRow value={identificacion_contacto} label={DocToString(documento_contacto as any)} />
                <TextRow value={telefono_contacto} label="Telefono" />

                <TextRow value={tipo_envio} label="Tipo de Envío" />
                <TextRow value={ciudad} label="Ciudad" />
                <TextRow value={sector} label="Sector" />
                <TextRow value={direccion} label="Direccion" />
                <TextRow value={referencia} label="Referencia" />


                <SectionHeader>DATOS DE FACTURACIÓN</SectionHeader>
                <TextRow value={identificacion} label={DocToString(documento as any)} />
                <TextRow value={razonSocial} label="Nombre" />
                <TextRow value={direccion_factura} label="Dirección" />
                <TextRow value={telefono_factura} label="Teléfono" />
                <TextRow value={email_factura} label="Email" />

                <PagoSection {...this.state} />


                <SectionHeader>ENCUESTA</SectionHeader>
                <Typography style={{ marginTop: 10 }} variant="caption">Cómo podemos ayudarte a mejorar tus ventas?</Typography>
                <Typography>{survey1 || ""}</Typography>
                <Typography style={{ marginTop: 10 }} variant="caption">
                    Compártenos alguna experiencia que hayan tenido tus clientes usando nuestros productos
                </Typography>
                <Typography>{survey2 || ""}</Typography>

                {estado === "PENDIENTE" && editable && <>
                    <SectionHeader>APROBACIÓN</SectionHeader>
                    <RegistroMultiPago
                        value={this.state._pagos}
                        onChange={this.handlePagos}
                    />
                    <ActionButtons
                        loadingAction={this.state.loading}
                        label="APROBAR"
                        showReject={true}
                        onClick={this.handleButton}
                    />
                </>}

                {estado === "APROBADO" && editable && <EnviarAction
                    onSubmit={this.handleButton}
                    _fechaEnvio={this.state._fechaEnvio}
                    onTextChange={this.handleChange}
                    onImageChange={this.handleEnvioImg}
                    obsequios={this.state._obsequios}
                    onObsequiosChanged={this.handleObsequiosChange}
                    loadingStatus={this.state._envioImgSrcStatus || this.state.loading}
                    src={this.state._envioImgSrc}
                />}

                {estado === "ENVIADO" && editable && <React.Fragment>
                    <SectionHeader>REGISTRAR FACTURA</SectionHeader>
                    <TextField
                        label="Numero de Factura"
                        placeholder="###-###-#####"
                        value={numeroFactura}
                        inputProps={{
                            id: "numeroFactura",
                            maxLength: 20
                        }}
                        fullWidth
                        onChange={this.handleChange}
                        name="numeroFactura"
                    />
                    <ActionButtons
                        loadingAction={this.state.loading}
                        label="FACTURAR"
                        showReject={true}
                        onClick={this.handleButton}
                    />
                </React.Fragment>}

                {estado === "FACTURADO" && editable && <ActionButtons
                    loadingAction={this.state.loading}
                    onClick={this.handleButton}
                    showReject="ONLY"
                />}

            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose}>SALIR</Button>
            </DialogActions>
        </Dialog>);
    }
}

export interface OrderDetailsState extends Partial<GetOrder> {
    loading: "data" | "data-error" | "APROBAR" | "RECHAZAR" | "FACTURAR" | "ENVIAR";
    data_error_message?: string;
    _fechaRecepcion: string;
    shouldRefresh?: boolean;
    _pagos: SavePayment[]

    _fechaEnvio: string;
    _obsequios?: Obsequio[];
    _envioImgSrc?: string;
    _envioImgFile?: File;
    _envioImgSrcStatus?: string | null;

    /** undefined (loading) | null (no hay datos) | object (datos) */
    urls?: { ride: string, xml: string, total: number, tipo_documento?: string } | null;
}
