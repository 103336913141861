import React from "react";
import { Autocomplete } from "@material-ui/lab"
import Input from "../Input";

export default function Dropdown<T extends { [key: string]: any }>(props: {
    value: T[typeof props["idKey"]]
    , items: T[]
    , error?: string
    , loading?: boolean
    , idKey: keyof T
    , displayNameKey: keyof T
    , getOptionLabel?: (item: T) => string
    , getOptionSelected?: (item: T, value: T) => boolean
    , onChange(item: T): void
        , placeholder?: string
}) {
    const { loading, items, error, placeholder
        , idKey, displayNameKey
        , getOptionLabel, getOptionSelected } = props;

    if (props.value !== undefined && props.value !== null) {
        var selectedItem = items.find(x => x[idKey] === props.value);
    }
    if (selectedItem === undefined) selectedItem = null;


    return <Autocomplete
        options={items}
        loading={loading}
        fullWidth
        loadingText="Cargando datos..."
        noOptionsText="No se encontraron registros"
        renderInput={params => <Input
            placeholder={loading ? "CARGANDO DATOS..." : placeholder ?? "Selecciona un item"}
            {...params}
            error={error}
            name={idKey.toString()}
        />}
        getOptionLabel={getOptionLabel || (item => item[displayNameKey])}
        value={selectedItem}
        onChange={(_, value: T) => props.onChange(value && value.id)}
        getOptionSelected={getOptionSelected || ((item, value) => item[idKey] === value[idKey])}
    />
}