import React from "react"
import API from "../../Services/API";
import LoadingPage from "../Common/Loading";
import ErrorPage from "../Common/Error";
import { Route } from "react-router-dom";
import { IconButton, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Menu, List, MenuItem, ListItemIcon, Typography, Paper } from "@material-ui/core";
import { Add, ListAlt, Menu as MenuIcon } from "@material-ui/icons";
import RoleList from "./RoleList";

export default function Roles(props: {
    onMounted?: any,
    match?: any,
    history?: any
}) {

    const [status, set_status] = React.useState("INITIALIZE");
    const [data, set_data] = React.useState([]);
    const [error, set_error] = React.useState<Error | null>(null);


    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { history, match, onMounted } = props;

    React.useEffect(() => {
        onMounted && onMounted(match.path);
    }, []);

    React.useEffect(() => {
        if (status !== "INITIALIZE") return;
        let cancel = false;
        API.GetRoles()
            .then(items => {
                if (cancel) return;
                set_status("");
                set_error(null);
                set_data(items);
            })
            .catch(ex => {
                if (cancel) return;
                set_status("ERROR");
                set_error(ex);
            });

        return () => {
            cancel = true;
        }
    }, [status]);

    if (["INITIALIZE", "CHANGE"].includes(status)) return <LoadingPage />;
    if (error) return <ErrorPage {...error} />;


    const newRole = () => {
        let nombre = window.prompt("Escribe el nombre del rol")?.trim();
        if (!nombre?.length) return;

        let simbolo = window.prompt("Escribe el símbolo (emoji)")?.trim();
        if (!simbolo?.length) return;

        set_status("CHANGE");
        API.createRole({
            nombre, simbolo
        }).then(() => {
            set_status("INITIALIZE")
        }).catch(ex => {
            set_status("");
            alert(ex.message);
        });
    }

    const closeRoleList = () => {
        history.push(match.path);
    }

    return <Paper>
        <List>
            <ListItem button onClick={newRole}>
                <ListItemText style={{ textAlign: "center" }}>
                    Crear nuevo rol
                </ListItemText>
            </ListItem>
            {data.map(item => (<ListItem key={item.id} button onClick={() => history.push(match.path + "/" + item.id)}>
                <ListItemAvatar>
                    {item.simbolo}
                </ListItemAvatar>
                <ListItemText>
                    {item.nombre}
                </ListItemText>
                <ListItemSecondaryAction style={{ display: "none" }}>
                    <IconButton value={item.id} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <MenuIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>))}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose();
                }}>
                    <ListItemIcon>
                        <ListAlt fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Editar nombre</Typography>
                </MenuItem>
            </Menu>
        </List>
        <Route path={match.path + "/:id"} children={({ match }) => <RoleList
            show={!!match?.params?.id}
            roleId={match?.params?.id}
            onClose={closeRoleList}
        />} />
    </Paper>
}