import React from "react";
import DataTable from "../DataTable";

export default class Queue extends React.PureComponent<{
    data: any[],
    columns: any[],
    estado: string,
    onClick: (id: number) => void
}> {
    handleClick = values => {
        this.props.onClick(values[0]);
    }
    render() {
        const { data, columns } = this.props;
        const rows = data.filter(x => x.estado === this.props.estado)
            .sort((a, b) => a.changedAt - b.changedAt)
        return (
            <DataTable
                title=""
                data={rows}
                columns={columns}
                options={{
                    selectableRows: "none",
                    onRowClick: this.handleClick
                }}
            />
        )
    }
}