import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import { match } from "react-router-dom";
import { FlexTabContainer, FlexPaperContentTab } from "./FlexTab";

export default function (props: {
    onMounted?(path: string): void;
    match?: match;
    tabs: TabLayoutItem[];
    fullHeight?: boolean;
    paperContent?: boolean;
    style?: React.CSSProperties
}) {
    const [currentTab, set_currentTab] = React.useState(props.tabs[0].tabId);

    React.useEffect(() => {
        props.onMounted && props.match && props.onMounted(props.match.path);
    }, []);

    const selectedTab = props.tabs.find(x => x.tabId === currentTab);
    if (selectedTab) var Children = <selectedTab.Component />;
    return (<FlexTabContainer style={{
        ...(props.paperContent ? { paddingLeft: 10, paddingRight: 10 } : {}),
        ...(props.style || {})
    }}
        fullHeight={props.fullHeight}
    >
        <Tabs value={currentTab} onChange={(ev, new_tab_id) => set_currentTab(new_tab_id)}>
            {props.tabs.map(x => <Tab key={x.tabId} value={x.tabId} label={x.tabLabel} />)}
        </Tabs>
        {
            props.paperContent
                ? <FlexPaperContentTab>{Children}</FlexPaperContentTab>
                : Children
        }
    </ FlexTabContainer >);
}

export interface TabLayoutItem {
    tabLabel: React.ReactNode;
    Component: React.ComponentType<any>;
    tabId?: string;
}
