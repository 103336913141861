import React from "react";
import { Grid } from "@material-ui/core";
import ProductCard from "./ProductCard";

let update_times = 0;

export default function ProductGrid(props: {
    Products: FullProduct[];

    onActionClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    editablePrices?: boolean;
    onPriceChange?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;

    controls?: "ADD-REMOVE";
    productIsVisible?(product: FullProduct): boolean;
    onCardClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}) {

    return (
        <Grid
            container
            alignItems="center"
            style={{ flex: 1, marginTop: 5 }}
            spacing={3}>
            {props.Products.map(item => (
                <Grid item sm={4} md={3} xs={12} key={item.id}>
                    <ProductCard
                        {...item}
                        editablePrice={props.editablePrices}
                        onPriceChange={props.onPriceChange}
                        onActionClick={props.onActionClick}
                        onCardClick={props.onCardClick}
                        controls={props.controls}
                    />
                </Grid>
            ))}
        </Grid>)
}